.pills-holder {
  margin-bottom: 10px;

  .title {
    display: block;
    font-weight: 500;
    color: $neutral-n5;
    margin-bottom: 8px;
  }

  .selected {
    background-color: $primary-p2;
  }
}

.pills-btn {
  background-color: $neutral-n3;
  color: $neutral-n7;
  font-weight: 500;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 25px;
  margin: 0 5px 5px 0;

  .text {
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-top;
  }

  .icon {
    background-color: $primary;
    color: $neutral-n1;
    width: 1rem;
    height: 1rem;
    padding: 4px;
    font-size: 7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .icon-pre {
    font-size: $H11;
  }

  &.pills-btn-v2 {
    background-color: $primary-p1;
    color: $primary-p8;

    .icon {
      background-color: $neutral-n1;
      color: $neutral-n8;
    }
  }
}
