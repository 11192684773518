ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  color: $neutral-n3;
  opacity: 100%;
}

.cp {
  cursor: pointer;
}

.container-fluid {
  padding: 0;
}

.container-xxxl {
  max-width: 1850px !important;
  padding: 0 20px;
}

label {
  &.required {
    &:after {
      content: "*";
      color: $error-r2;
    }
  }
}

.input-errors {
  font-size: $H11;
  display: grid;
}

input[type="checkbox"] {
  border-radius: 0.125em !important;
  width: $H13;
  height: $H13;
}

input[type="checkbox"]:disabled {
  opacity: 0.3;
  cursor: default !important;
}

.btn-primary:disabled {
  background-color: $neutral-n6;
  border: 1px solid $neutral-n6;
}

.ng-select.ng-select-single .ng-select-container {
  height: 38px;
}

.ng-select .ng-select-container {
  border: 1px solid $neutral-n3 !important;
  border-radius: $input-border-radius;
}

.ng-dropdown-panel {
  top: 118% !important;
  border-radius: 10px !important;
  min-width: 100px;
  background: $neutral-n1;
  border: 1px solid $neutral-n3;

  &.ng-dropdown-panel.ng-select-top {
    border: 0;
    border-radius: 10px !important;
  }

  .ng-dropdown-panel-items {
    box-shadow: 0px 8px 30px rgba(63, 68, 72, 0.08);
    border-radius: 10px;
    scrollbar-width: thin;
  }

  .ng-option-label {
    color: $neutral-n8;
    font-size: $H13;
  }
}

.icon-telq-id {
  font-size: 1rem;
  margin: 0 0.125em;
}

.v-middle {
  vertical-align: middle;
}

// Table Related

// Input fields and buttons over tables ...
// Mostly present in index components ...
.table-topbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .search-holder {
    width: 350px;
    padding-right: 38px;
    position: relative;

    input {
      border-radius: 50px;
      padding-left: 40px;
    }

    .icon-search {
      position: absolute;
      top: 12px;
      left: 15px;
      font-size: $H16;
    }
  }

  .table-action-holder {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .import {
      a {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid $neutral-n4;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        font-size: $H20;
      }
    }

    .btn {
      font-size: $H12;

      span {
        color: #fff !important;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
}

.results {
  display: flex;
  align-items: center;
}

.table-wrapper {
  &.height-full {
    overflow-y: auto;
    scrollbar-width: thin;

    th {
      span.sort {
        cursor: pointer;
      }
    }
  }

  .table-wrapper {
    max-height: 100%;
    min-height: 0;
  }

  .table-empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    text-align: center;
    padding: 30px;

    .state-wrapper {
      width: 450px;
    }

    p {
      font-size: $H14;
      color: $neutral-n5;
    }

    .text-lg {
      font-size: $H16;
      font-weight: 600;
    }
  }

  .sub-tables {
    border-left: 2px solid $neutral-n3;

    .table-responsive {
      height: 100% !important;
    }
  }

  .text-cut {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.table-responsive {
  scrollbar-width: thin;
  overflow-y: auto;
  border: 1px solid $neutral-n5;
  border-radius: 16px;
  margin-bottom: 0.625rem;
}

.table-responsive::-webkit-scrollbar {
  width: 0px;
}

table {
  margin-bottom: 0px !important;

  tr:nth-child(odd) td {
    background-color: $neutral-n2;
  }

  th {
    position: sticky;
    top: 0px;
    z-index: 2;
    height: 48px;
    vertical-align: middle !important;
    white-space: unset !important;
    text-overflow: unset !important;
  }

  th,
  td {
    background-color: $white;
    overflow-wrap: break-word;
    max-width: 100px;
    min-width: 60px;
    vertical-align: middle;
    padding: 10px 12px !important;
    background-clip: padding-box;

    &.w-xxs {
      width: 10px !important;
    }

    &.w-xsm {
      max-width: 50px;
      min-width: 30px;
    }

    &.w-sm {
      max-width: 80px;
      min-width: 50px;
    }

    &.w-md {
      max-width: 120px;
      min-width: 70px;
    }

    &.w-mlg {
      max-width: 160px;
      min-width: 125px;
    }

    &.w-lg {
      max-width: 200px;
      min-width: 200px;
    }
  }

  td.full-content {
    white-space: normal;
    overflow: visible;
  }

  td.fit-content {
    max-width: fit-content;
  }

  input[type="checkbox"] {
    border-radius: 0.125em !important;
    width: $H13;
    height: $H13;
  }

  thead {
    th {
      color: $neutral-n9;

      &.check {
        padding: 10px 5px 10px 10px !important;
        width: 18px;
        min-width: 0;
        max-width: 30px;
      }

      .head-title {
        position: relative;
        font-size: 12px;
        font-weight: 600;
      }

      &.no-icon {
        z-index: 3;

        .head-title {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  tbody {
    th,
    td {
      font-weight: 500;
      color: $neutral-n8;

      .label {
        padding: 3px 8px;
        border-radius: 12px;

        &.label-success {
          background: $sucess-s2;
        }

        &.label-danger {
          background: $error-r2;
        }
      }

      .highlighted {
        border-radius: 8px;
        background-color: $primary-p3;
        padding: 4px 6px;
        line-height: 2em;

        &.error {
          background-color: #f9f2f4;
          color: $error-r2;
        }

        &.success {
          background-color: #f3f9f2;
          border-radius: 8px;
          color: $sucess-s2;
        }
      }

      &.editable {
        text-decoration: underline dashed $neutral-n7;
        text-underline-offset: 3px;

        &:hover {
          cursor: pointer;
          text-decoration: underline solid $neutral-n7;
        }
      }
    }

    th.check {
      padding: 10px 5px 10px 10px !important;
      width: 18px;
      min-width: 0;
      max-width: 30px;
      vertical-align: middle !important;
    }

    .child {
      background: $neutral-n2;
    }

    .action-icons-horizontal {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5em;
      color: $neutral-n8;

      .action-icons:hover {
        text-decoration: underline;
        font-weight: bold;
      }

      .marked {
        color: $accent-a1;
      }
    }
  }

  .dropdown-toggle {
    color: $neutral-n6;
    padding: 5px;
    font-size: $H14;

    &:hover {
      cursor: pointer;
    }

    &::after {
      display: none;
    }

    &.has-dot {
      position: relative;

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $accent-a6;
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }
  }

  .profile-name {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #ecdfff;
    color: $primary;
    font-size: $H11;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
    padding: 5px;
    text-transform: uppercase;
  }

  .email {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dropdown-menu {
    cursor: pointer;
    border: none;
    min-width: 100px !important;
    box-shadow: 0px 8px 30px rgba(63, 68, 72, 0.08);
    border-radius: 10px;
    background-color: $neutral-n3;

    a {
      color: $neutral-n7;
      padding: 5px 12px;
      display: flex;
      align-items: center;
    }

    span {
      font-size: $H13;
      margin-right: 4px;
    }

    .action-icons {
      width: 15px;
      display: flex;
      // justify-content: center;
    }

    .action-text {
      font-size: 12px;
      font-weight: 500px;
    }
  }

  .status-icons {
    border: 1px solid $neutral-n5;
    border-radius: $H16;
    color: $neutral-n5;
    font-size: $H20;
    font-weight: 400;
    gap: 3px;
    padding: 5px 5px;
    max-width: 70px;
    width: fit-content;

    span {
      &:before {
        color: $neutral-n6;
      }
      &.success {
        &:before {
          color: $sucess-s2;
        }
      }
      &.error {
        &:before {
          color: $error-r2;
        }
      }
      &.accent {
        &:before {
          color: $accent-a2;
        }
      }
    }

    .text-sm {
      font-size: $H13;
    }

    .icon-bug-red {
      width: 16px;
      height: 16px;
    }
  }

  .color-neutral-n8 {
    color: $neutral-n8 !important;
  }
  .color-sucess-s2 {
    color: $sucess-s2 !important;
  }
  .color-accent {
    color: $accent-a2 !important;
  }
  .color-error {
    color: $error-r3 !important;
  }

  .btn {
    padding: 5px 9px;
    font-size: 12px;
  }
}

.inc-dec-input {
  height: auto;
  border: 0;
  width: 50px;
  padding: 0;
}

.form-switch {
  .form-check-input {
    border-radius: 0.625em !important;
    cursor: pointer;
    height: $H14;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.40)'/></svg>");

    &:checked {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0)'/%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
    }

    &.medium {
      border-radius: 18px !important;
      height: 18px;
      width:  32px;
    }

    &.large {
      border-radius: 2rem !important;
      height: 2.5em;
      width: 4.5em;
    }
  }
}

.filter-top-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 12px 0 0 0;
}

.filter-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0;
  flex: 1;
  max-width: 85%;

  .delete-area {
    border-radius: 25px;
    gap: 6px;
    font-weight: 500;
    color: $neutral-n8;

    .fa-trash-alt {
      font-size: $H14;
    }

    &:hover {
      color: $error-r3;
      cursor: pointer;
    }

    &.drag-active {
      border: 1px solid $error-r3;
      color: $error-r3;
      margin: 0px 16px;
      padding: 6px 12px;

      &:hover {
        background-color: $error-r3;
        color: $neutral-n4;
      }
    }
  }

  .filter-tabs-holder {
    display: flex;
    overflow-x: auto;
    max-width: 80%;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .example-custom-placeholder {
      background: $primary;
      border: dotted 1px $primary;
      min-height: 10px;
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .filter-tab {
    margin-right: 10px;

    > a {
      font-weight: 500;
      color: $neutral-n8;
      border: 1px solid $neutral-n5;
      border-radius: 25px;
      background-color: $white;
      padding: 6px 28px 6px 12px;
      display: inline-block;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:after {
        display: none;
      }

      &:before {
        content: "\e928";
        font-family: "icomoon";
        font-size: 10px;
        position: absolute;
        right: 12px;
        top: 8px;
        transform: rotate(-180deg);
        transition: all 0.2s linear;
      }

      &.show {
        &::before {
          transform: rotate(0deg);
          top: 7px;
        }
      }

      > .icon-drag-indicator {
        margin-right: 6px;
        cursor: grab;
      }

      &:hover {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  .filter-add-btn,
  .delete-area {
    padding: 0 8px;
  }

  .filter-add-btn {
    a {
      color: $neutral-n8;
      font-weight: 500;
      display: inline-block;

      &:hover {
        color: $neutral-n9;
      }
    }

    .icon-add {
      font-size: $H11;
    }
  }

  .filter-drop-top {
    input {
      height: 32px;
      border-radius: 25px;
    }

    .icon-search {
      left: 11px;
      top: 11px;
    }

    form {
      flex: 1;
      padding-right: 10px;
    }

    > a {
      color: $neutral-n8;
    }

    .icon-delete {
      width: 20px;
      display: inline-block;
      font-size: $H14;
    }
  }

  .filter-drop-list {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: -15px;
      right: -15px;
      bottom: 0;
      border-bottom: 1px solid $neutral-n3;
    }

    .filter-drop-title {
      color: $neutral-n5;
      font-weight: 500;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .filter-drop-body {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    .input-check {
      width: 18px;
      padding-top: 1px;
    }

    .select-text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 22px);
      font-size: $H13;
      color: $neutral-n8;
    }
  }
}

.filter-right-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 15%;
  padding: 15px 0;

  .rounded-icons {
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $neutral-n5;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      border-color: $primary;
      color: $primary;
    }
  }

  .filter-delete-btn {
    cursor: pointer;
    font-weight: 500;
    // border-left: 1.4px solid $neutral-n4;
    padding-left: 10px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -10px;
      top: -5px;
      bottom: -5px;
      border-left: 1.4px solid $neutral-n4;
    }

    .icon-delete {
      margin-right: 5px;
    }
  }

  > a {
    color: $neutral-n7;
  }
}

.create-test-box-holder {
  min-height: 35em;
  height: calc(60vh - 70px);

  .create-test-box {
    height: 100%;
    border-right: 1px solid $neutral-n4;

    .input-list-items {
      padding: 10px 0;
      max-height: 100px;
      overflow-y: auto;

      > li {
        color: $primary-p8;
        padding: 4px 10px;
        background-color: $primary-p2;
        display: inline-block;
        border-radius: 20px;
        font-weight: 500;
        margin-right: 8px;
        margin-bottom: 8px;

        button {
          background: white;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          padding: 2px 4px;
          margin-left: 5px;
          font-size: 9px;
          margin-top: -2px;

          i,
          span {
            &:before {
              color: $neutral-n8;
            }
          }
        }
      }
    }
  }

  &:last-child {
    .create-test-box {
      border-right: none;
    }
  }
}

.has-tooltip {
  position: relative;

  &:hover {
    cursor: pointer;

    .g-tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(-10px);
      z-index: 9;
    }
  }

  .g-tooltip {
    background-color: $primary-p10;
    bottom: 50%;
    border-radius: 8px;
    color: $neutral-n1;
    font-size: 0.75rem;
    font-weight: 400;
    padding: 5px 10px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);

    &.tooltip-lg {
      min-width: 215px;
    }

    &.tooltip-md {
      min-width: 130px;
    }
  }
}

// Ready to click inside table on hover ...
.editable {
  span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: anywhere;
    padding: 2px 8px;
    line-height: 1.5em;
    margin-left: -8px; // This is to vertically align the table header with the editable cells ...
    text-decoration: underline dashed $neutral-n7;
    text-underline-offset: 3px;
    width: fit-content;

    &:hover {
      cursor: pointer;
      background-color: $neutral-n4;
      border-radius: 8px;
      transition: all 0.3s linear;
      text-decoration: underline solid $neutral-n7;
    }
  }
}

// pagination

.pagination-component {
  max-width: 100%;
  width: 100% !important;
}

.has-pagination {
  position: relative;

  .pagination-left {
    flex: 1;
  }

  .pagination-right {
    position: absolute;
    right: 0;

    .pagination-count {
      margin-right: 2em;
      color: $neutral-n7;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .select-page {
    position: absolute;
    left: 0;
  }
}

.pagination-wrapper {
  .pagintion-holder {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.pagination {
  display: flex;
  margin: 5px 0 !important;
  align-items: center;
  justify-content: center;
  flex: 1;
  // margin-left: -100px !important;

  li {
    cursor: pointer;
    padding: 0 2px;

    a {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px !important;
      font-weight: 500;
      color: $neutral-n9 !important;
    }

    &.active {
      a {
        background: $primary-p4 !important;
        color: $neutral-n1 !important;
      }
    }
  }
}

.pagination-numbers {
  color: #a1a4a6;
  font-size: $H14;
}

.daterangepicker {
  border-radius: 16px;

  .ranges {
    font-size: $H13;

    li {
      color: $neutral-n8;
      margin-bottom: 4px;
      padding: 8px 16px;

      &.active {
        color: $primary;
        font-weight: 500;
        background-color: transparent !important;
      }

      &:hover {
        background-color: $neutral-n3;
      }
    }
  }

  .drp-calendar {
    &.left {
      padding-left: 8px !important;
    }

    &.right {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  .calendar-table {
    font-size: $H11;
    border-bottom: 1px solid #e7eaea;

    select {
      background-color: transparent;
      border: 0;
      font-size: $H11;
      color: $neutral-n8;
      font-weight: 600;
    }

    thead {
      tr {
        display: flex;
        &:last-child {
          th {
            min-width: 30px;
            line-height: 30px;
            max-height: 30px;
            height: 30px;
            max-width: 30px;
            padding: 4px 5px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
          }
        }

        .prev,
        .month,
        .next {
          width: auto;
          height: auto;
          max-width: none;
        }
      }

      th {
        color: #8b8c8c;
        font-size: $H11;
      }
    }

    tbody {
      th,
      td {
        position: relative;
        color: $neutral-n8;
        font-size: $H11;
        min-width: 30px;
        line-height: 30px;
        max-height: 30px;
        height: 30px;
        max-width: 30px;
        padding: 4px 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        // margin-right: 5px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: -5px;
          background-color: rgba(255, 0, 0, 0.392) a(255, 0, 0, 0.392);
        }

        &.active {
          background-color: $primary !important;
          color: $neutral-n1;
          border-radius: 50%;
        }
      }

      tr {
        display: flex;

        .in-range {
          background-color: $primary-p1;

          &:first-of-type {
            border-top-left-radius: 40%;
            border-bottom-left-radius: 40%;
          }
          &:last-of-type {
            border-top-right-radius: 40%;
            border-bottom-right-radius: 40%;
          }
        }
      }
    }
  }

  .calendar-time {
    margin: 12px auto 5px auto;

    select {
      &.disabled {
        color: $neutral-n6;
      }

      width: auto;
      height: 32px;
      border: 1px solid $neutral-n3;
      padding: 5px 8px;
      border-radius: 4px;
      background-color: $neutral-n1;
      margin: 0 5px;
    }
  }

  .drp-buttons {
    .drp-selected {
      color: #8b8c8c;
      font-weight: 500;
      border-right: 1px solid #eaeaea;
      padding: 5px 15px;
    }

    .cancelBtn {
      font-weight: 500;
    }

    .applyBtn {
      margin-left: 8px;
      font-size: $H13;
      font-weight: bold;
      padding: 8px 12px;
      font-weight: 500;
      border-radius: 30px;
    }
  }
}

.date-filter-drop {
  width: 16.5rem;

  .form-control {
    border-radius: 35px;
  }
}

* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 32px;
}
/* Works on firefox */
*::-moz--scrollbar {
  width: 6px;
  height: 6px;
}

*::-moz--scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 32px;
}

.table-action-info-bar {
  background-color: $primary-p10;
  box-shadow: 0px 8px 30px rgba(63, 68, 72, 0.08);
  border-radius: 32px;
  color: $primary-p2;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  max-width: max-content;
  position: fixed;
  bottom: 30px;
  left: calc(50% + 102px);
  z-index: 9;
  transform: translateX(-50%);
  transition: all 0.25s linear;

  .sidebar-toggle & {
    left: calc(50% + 38px);
  }

  .text-icon-holder {
    align-items: center;
    cursor: pointer;
    display: flex;

    .icon {
      width: 20px;
      display: flex;
      align-items: center;
    }

    .icon-done {
      width: $H13;
      height: $H13;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 8px;
      background-color: $neutral-n1;
      color: $primary-p8;
    }

    .text {
      flex: 1;
      font-size: $H13;
    }
  }

  .info-left {
    border-right: 1px solid rgba(#fff, 0.3);
    padding-right: 15px;
  }

  .info-right {
    display: flex;
    align-items: center;

    .text-icon-holder {
      margin-left: 15px;
    }
  }
}

dl.description-list {
  display: grid;
  grid-template-columns: max-content auto;

  dt {
    grid-column-start: 1;
  }
  dd {
    grid-column-start: 2;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 25px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  cursor: grabbing;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid $primary;
  background: $neutral-n1;
  opacity: 70%;
}

// This is to hide the scrollbar if the section is not under focus ...
.cdk-virtual-scrollable {
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    scrollbar-color: initial;
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

// Alerts ...
.alert-primary {
  background-color: $primary-p1;
  border: none;
  color: $primary-p9;
}

.alert-success {
  background-color: $sucess-bg;
  color: $sucess-s3;
}

.alert-warning {
  background-color: $warning-bg;
  color: $warning-w3;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}

.error-blink {
  animation: blinker 2s linear infinite;
  color: $error-r3;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}