.spinner {
  align-items: flex-start;
  background-color: #fdfdfd;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 10;
  opacity: 90%;
}

.sk-spinner {
  background-image: url("/img/telq-spinner.gif");
  background-size: contain;
  width: 25em;
  height: 25em;
  z-index: 11;

  &.small {
    width: 6em;
    height: 6em;
  }

  &.medium {
    width: 10em;
    height: 10em;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  display: inline-block;
  animation: spin 2s linear infinite;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid $primary-p5;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}