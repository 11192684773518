@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?1qq35t");
  src: url("../fonts/icomoon.eot?1qq35t#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?1qq35t") format("truetype"), url("../fonts/icomoon.woff?1qq35t") format("woff"),
    url("../fonts/icomoon.svg?1qq35t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left-plain:before {
  content: "\e9ab";
}
.icon-arrow-right-plain:before {
  content: "\e9ac";
}
.icon-ported:before {
  content: "\e9aa";
  color: #5930FB;
}
.icon-ported-white:before {
  content: "\e9aa";
  color: #FFFFFF;
}
.icon-bitcoin:before {
  content: "\e900";
}
.icon-ethereum:before {
  content: "\e902";
}
.icon-favourite:before {
  content: "\e909";
}
.icon-telq-id .path1:before {
  content: "\e90a";
  color: rgb(88, 51, 251);
}
.icon-telq-id .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(249, 250, 250);
}
.icon-telq-id .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(249, 250, 250);
}
.icon-litecoin:before {
  content: "\e90d";
}
.icon-notification .path1:before {
  content: "\e90e";
  color: rgb(210, 206, 222);
}
.icon-notification .path2:before {
  content: "\e90f";
  margin-left: -0.9091796875em;
  color: rgb(210, 206, 222);
}
.icon-notification .path3:before {
  content: "\e910";
  margin-left: -0.9091796875em;
  color: rgb(255, 135, 8);
}
.icon-sorting:before {
  content: "\e912";
}
.icon-templates:before {
  content: "\e913";
}
.icon-webmoney:before {
  content: "\e914";
}
.icon-way:before {
  content: "\e915";
}
.icon-a-2-sub:before {
  content: "\e916";
}
.icon-a-2-sup:before {
  content: "\e917";
}
.icon-account:before {
  content: "\e918";
}
.icon-actions-hor:before {
  content: "\e919";
}
.icon-add-link:before {
  content: "\e91a";
}
.icon-add-phone-numbers:before {
  content: "\e91b";
}
.icon-add-templates:before {
  content: "\e91c";
}
.icon-add:before {
  content: "\e91d";
}
.icon-admin-settings:before {
  content: "\e91e";
}
.icon-analytics:before {
  content: "\e91f";
}
.icon-api:before {
  content: "\e920";
}
.icon-arrow-down-1:before {
  content: "\e921";
}
.icon-arrow-down:before {
  content: "\e922";
}
.icon-arrow-left:before {
  content: "\e923";
}
.icon-arrow-right:before {
  content: "\e924";
}
.icon-arrow-turn-left-1:before {
  content: "\e925";
}
.icon-arrow-turn-left:before {
  content: "\e926";
}
.icon-arrow-turn-right:before {
  content: "\e927";
}
.icon-arrow-up:before {
  content: "\e928";
}
.icon-arrows-up:before {
  content: "\e929";
}
.icon-attention:before {
  content: "\e92a";
}
.icon-bold:before {
  content: "\e92b";
}
.icon-calendar:before {
  content: "\e92c";
}
.icon-check:before {
  content: "\e92d";
}
.icon-clock:before {
  content: "\e92e";
}
.icon-close-full-screen:before {
  content: "\e92f";
}
.icon-close:before {
  content: "\e930";
}
.icon-comment-added .path1:before {
  content: "\e931";
  color: rgb(67, 73, 78);
}
.icon-comment-added .path2:before {
  content: "\e932";
  margin-left: -0.921875em;
  color: rgb(37, 140, 253);
}
.icon-comment:before {
  content: "\e933";
}
.icon-connections:before {
  content: "\e934";
}
.icon-content:before {
  content: "\e935";
}
.icon-coonections:before {
  content: "\e936";
}
.icon-copy:before {
  content: "\e937";
}
.icon-countries:before {
  content: "\e938";
}
.icon-created:before {
  content: "\e939";
}
.icon-crossed:before {
  content: "\e93a";
}
.icon-dashboard:before {
  content: "\e93b";
}
.icon-delete:before {
  content: "\e93c";
}
.icon-delivered:before {
  content: "\e93d";
}
.icon-domestic:before {
  content: "\e93e";
}
.icon-done:before {
  content: "\e93f";
}
.icon-downgrade:before {
  content: "\e940";
}
.icon-download-generated-invoice:before {
  content: "\e941";
}
.icon-drag-indicator:before {
  content: "\e942";
}
.icon-edit:before {
  content: "\e943";
}
.icon-enroute-unknown:before {
  content: "\e944";
}
.icon-error:before {
  content: "\e945";
}
.icon-expand:before {
  content: "\e946";
}
.icon-expired:before {
  content: "\e947";
}
.icon-export:before {
  content: "\e948";
}
.icon-external:before {
  content: "\e949";
}
.icon-file:before {
  content: "\e94a";
}
.icon-filter-1:before {
  content: "\e94b";
}
.icon-filter:before {
  content: "\e94c";
}
.icon-full-screen:before {
  content: "\e94d";
}
.icon-group:before {
  content: "\e950";
}
.icon-h3:before {
  content: "\e951";
}
.icon-header-2:before {
  content: "\e952";
}
.icon-header-4:before {
  content: "\e953";
}
.icon-help-1:before {
  content: "\e954";
}
.icon-help:before {
  content: "\e955";
}
.icon-hide:before {
  content: "\e956";
}
.icon-image:before {
  content: "\e957";
}
.icon-import-from-file:before {
  content: "\e958";
}
.icon-import:before {
  content: "\e959";
}
.icon-in-process:before {
  content: "\e95a";
}
.icon-info-1:before {
  content: "\e95b";
}
.icon-internal-error:before {
  content: "\e95d";
}
.icon-international:before {
  content: "\e95e";
}
.icon-italic:before {
  content: "\e960";
}
.icon-line:before {
  content: "\e961";
}
.icon-link-1:before {
  content: "\e962";
}
.icon-link:before {
  content: "\e963";
}
.icon-live-Number-Testing:before {
  content: "\e964";
}
.icon-log-in:before {
  content: "\e965";
}
.icon-log-out:before {
  content: "\e966";
}
.icon-logs:before {
  content: "\e967";
}
.icon-long-code:before {
  content: "\e968";
}
.icon-manual-testing:before {
  content: "\e969";
}
.icon-mo-testing:before {
  content: "\e96a";
}
.icon-modems:before {
  content: "\e96b";
}
.icon-more:before {
  content: "\e96c";
}
.icon-negative:before {
  content: "\e96d";
}
.icon-network-offline:before {
  content: "\e96e";
}
.icon-network-1:before {
  content: "\e96f";
}
.icon-network:before {
  content: "\e970";
}
.icon-no-dlr-received:before {
  content: "\e971";
}
.icon-paragraph:before {
  content: "\e972";
}
.icon-payments:before {
  content: "\e973";
}
.icon-phonenumber-report:before {
  content: "\e974";
}
.icon-play:before {
  content: "\e975";
}
.icon-plus:before {
  content: "\e976";
}
.icon-positive:before {
  content: "\e977";
}
.icon-pre-registration:before {
  content: "\e978";
}
.icon-price:before {
  content: "\e979";
}
.icon-promotional:before {
  content: "\e97a";
}
.icon-publish:before {
  content: "\e97b";
}
.icon-quote:before {
  content: "\e97c";
}
.icon-refresh:before {
  content: "\e97d";
}
.icon-remove-format:before {
  content: "\e97e";
}
.icon-remove-Link:before {
  content: "\e97f";
}
.icon-robot:before {
  content: "\e980";
}
.icon-scheduler:before {
  content: "\e981";
}
.icon-search:before {
  content: "\e982";
}
.icon-security:before {
  content: "\e983";
}
.icon-senders-1:before {
  content: "\e984";
}
.icon-senders:before {
  content: "\e985";
}
.icon-setting-2:before {
  content: "\e986";
}
.icon-settings:before {
  content: "\e988";
}
.icon-show:before {
  content: "\e989";
}
.icon-smpp-connection-offline:before {
  content: "\e98a";
}
.icon-smsc-dlr:before {
  content: "\e98b";
}
.icon-star:before {
  content: "\e98c";
}
.icon-status-1:before {
  content: "\e98d";
}
.icon-status .path1:before {
  content: "\e98e";
  color: rgb(67, 73, 78);
}
.icon-status .path2:before {
  content: "\e98f";
  margin-left: -1.0419921875em;
  color: rgb(76, 185, 107);
}
.icon-stop:before {
  content: "\e990";
}
.icon-subaccount:before {
  content: "\e991";
}
.icon-suppliers:before {
  content: "\e992";
}
.icon-templates-1:before {
  content: "\e993";
}
.icon-templates-2:before {
  content: "\e994";
}
.icon-semplates:before {
  content: "\e995";
}
.icon-test-case:before {
  content: "\e996";
}
.icon-test-number-not-available:before {
  content: "\e997";
}
.icon-test-number-offline:before {
  content: "\e998";
}
.icon-test-results:before {
  content: "\e999";
}
.icon-test-sender-id-replaced:before {
  content: "\e99a";
}
.icon-tests-settings:before {
  content: "\e99b";
}
.icon-tests:before {
  content: "\e99c";
}
.icon-text-align-center:before {
  content: "\e99d";
}
.icon-text-align-justified:before {
  content: "\e99e";
}
.icon-text-align-left:before {
  content: "\e99f";
}
.icon-text-align-right:before {
  content: "\e9a0";
}
.icon-text-by-list:before {
  content: "\e9a1";
}
.icon-text-by-numeric:before {
  content: "\e9a2";
}
.icon-time:before {
  content: "\e9a3";
}
.icon-tour:before {
  content: "\e9a4";
}
.icon-user:before {
  content: "\e9a5";
}
.icon-users:before {
  content: "\e9a6";
}
.icon-video:before {
  content: "\e9a7";
}
.icon-wait:before {
  content: "\e9a8";
}
.icon-warning:before {
  content: "\e9a9";
}
