@import "telq.scss";

.ag-grid {
  display: block;
}

.ag-root-wrapper {
  border: none;
}

.ag-statuses-bar {
  width: 100%;
  margin-top: 12px;
  overflow: hidden;
  border-radius: 5px;
}

.ag-statuses-bar > div {
  height: 10px;
  float: left;
}

.ag-statuses-bar > div.color-danger {
  background-color: $error-r2;
}
.ag-statuses-bar > div.color-warning {
  background-color: $accent-a2;
}
.ag-statuses-bar > div.color-navy {
  background-color: $sucess-s2;
}
.ag-statuses-bar > div.color-gray {
  background-color: #888888;
}

.ag-root-wrapper{
  border: 1px solid $neutral-n4;
  border-radius: 12px;
}

.ag-menu-option:hover {
  background-color: $neutral-n3;
  cursor: pointer;
}

.ag-menu-option.ag-menu-option-disabled:hover {
  background-color: unset;
  color: unset;
  cursor: default;
}

.ag-cell-wrapper {
  height: 100%;
}

.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: none;
}

.ag-header-cell,
.ag-cell {
  border-right: 1px solid $neutral-n4 !important;
  border-bottom: 1px solid $neutral-n4 !important;
}

.ag-header-cell-resize:hover {
  background: $primary-p4;
  width: 8px;
}

.ag-row {
  color: $neutral-n9;
  font-size: 12px !important;
  font-weight: 500;
}

.ag-row-even {
  background-color: #f9f9f9;
}

.ag-center-cols-clipper,
.ag-center-cols-container {
  // For empty table ...
  min-height: 500px !important;
}

.ag-theme-alpine .ag-filter-toolpanel-header,
.ag-theme-alpine .ag-filter-toolpanel-search,
.ag-theme-alpine .ag-status-bar,
.ag-theme-alpine .ag-header-row,
.ag-theme-alpine .ag-panel-title-bar-title,
.ag-theme-alpine .ag-multi-filter-group-title-bar,
.ag-theme-alpine-dark .ag-filter-toolpanel-header,
.ag-theme-alpine-dark .ag-filter-toolpanel-search,
.ag-theme-alpine-dark .ag-status-bar,
.ag-theme-alpine-dark .ag-header-row,
.ag-theme-alpine-dark .ag-panel-title-bar-title,
.ag-theme-alpine-dark .ag-multi-filter-group-title-bar {
  color: $neutral-n9;
  font-weight: 700;
  font-size: 13px;
}

.ag-theme-alpine {
  --ag-font-family: "Inter", $font-family-base;
  --ag-font-size: 12px;
  --ag-grid-size: 5px;
  --ag-header-height: 55px;
  --ag-tab-min-width: 200px;
  --ag-row-hover-color: #f7f4ff;
  --ag-border-color: $neutral-n4;
  --ag-row-border-color: $neutral-n4;
  --ag-odd-row-background-color: $neutral-n1;
  --ag-header-background-color: $neutral-n1;
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 10px;
  --ag-header-column-resize-handle-color: $neutral-n4;
  --ag-value-change-value-highlight-background-color: #f7f4ff;
  --ag-selected-row-background-color: #ebf0ff;
  --ag-header-column-resize-handle-height: 25%;
  --ag-range-selection-border-style: dashed;
  --ag-range-selection-border-color: #D9D2F5;
  --ag-range-selection-background-color: #F1EEFA;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  color: $primary-p6 !important;
}

.ag-center-cols-viewport {
  min-height: 176px !important;

  .icon-bug-red {
    width: 16px;
    height: 16px;
  }
}

// Specific to the session supplier table ...
.session-supplier-table-container {
  .ag-header-cell,
  .ag-cell,
  .ag-details-row,
  .ag-details-row .ag-cell {
    border: none !important;
  }

  .ag-cell,
  .ag-details-row .ag-cell {
    border-top: 1px solid $neutral-n4 !important;
  }

  .ag-pinned-left-cols-container,
  .ag-center-cols-viewport,
  .ag-pinned-right-cols-container {
    .ag-row-group-expanded {
      background-color: $primary-p1;

      .fa-trash-alt {
        color: $error-r3;
      }
    }
  }

  .ag-header-viewport {
    .ag-header-container {
      .ag-header-row {
        .ag-header-cell {
          .ag-wrapper {
            left: -1px;
          }
        }
      }
    }
  }

  .ag-header-cell-text {
    font-size: $H11;
    font-weight: 600;
    color: $neutral-n7;
  }

  .host-cell::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .online::after {
    background-color: $sucess-s2;
  }

  .offline::after {
    background-color: $error-r2;
  }

  .ag-row-even {
    background-color: unset;
  }

  .ag-details-row {
    background-color: $primary-p1;
    padding: 12px 32px 24px 30px;

    .ag-root-wrapper {
      .ag-header {
        background: $neutral-n2;

        .ag-column-first {
          left: 2px;
        }
      }

      .ag-body-viewport {
        .ag-cell {
          border-top: 1px solid $neutral-n3 !important;
        }

        .ag-pinned-left-cols-container {
          .ag-cell-wrapper {
            margin-left: 1px;
          }
        }
      }

      .ag-paging-panel {
        border-top-color: $neutral-n4;
        height: 36px;
      }

      .ag-status-bar {
        display: flex;
        align-items: center;
        height: 48px;
        border-top: 1px solid $neutral-n3;

        .ag-status-bar-right {
          width: 100%;
          display: unset;
        }
      }

      .action-cell {
        .ag-header-cell-comp-wrapper {
          width: 40%;
        }
      }
    }
  }
}

// Specific to users table ...
.users-table-container {
  .ag-header-cell {
    .ag-checkbox {
      .ag-checkbox-input-wrapper {
        margin-left: -1px !important;
      }
    }
  }
}

// Specific to the special pricing group table ...
.special-pricing-table-container {
  .ag-row-group-expanded {
    background-color: $primary-p1;
  }

  .ag-row-level-1 {
    background-color: $primary-p1;
  }

  .special-table-container {
    
    .ag-header-row {

      background: $neutral-n2;

      .ag-header-cell-text {
        font-size: $H12;
        font-weight: 600;
        color: $neutral-n7;
        text-transform: uppercase;
      }
    }

    .ag-row-even {
      background-color: unset;
    }

    .ag-center-cols-viewport {
      min-height: auto !important;
      .ag-center-cols-container {
        min-height: auto !important;
      }
    }
  }
}

